*
{
    margin: 0%;
    padding: 0%;
    box-sizing: border-box;
}
/* banner starts here  */
.corpbg
{
    background-image: url('../Components/images/pic_paralax_2.jpg');
    width: 100%;
    height: 430px;
}

/* banner ends here  */

/* why cjhoose coporate starts  */

.card-title1 {
    color: #262626;
    font-size: 22px;
    line-height: normal;
    font-weight: 700;
    margin-bottom: 0.5em;
  }
  
  .small-desc {
    font-size: 1em;
    font-weight: 400;
    line-height: 1.5em;
    color: #06082f;
  }
  
  .small-desc {
    font-size: 14px;
  }
  
  .go-corner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 2em;
    height: 2em;
    overflow: hidden;
    top: 0;
    right: 0;
    background: linear-gradient(135deg, #6293c8, #384c6c);
    border-radius: 0 4px 0 32px;
  }
  
  .go-arrow {
    margin-top: -4px;
    margin-right: -4px;
    color: white;
    font-family: courier, sans;
  }
  
  .card-run {
    display: block;
    position: relative;
    max-width: 300px;
    max-height: 420px;
    background-color: #f2f8f9;
    border-radius: 10px;
    padding: 2em 1.2em;
    margin: 12px;
    text-decoration: none;
    z-index: 0;
    overflow: hidden;
    background: linear-gradient(to bottom, #c3e6ec, #a7d1d9);
    font-family: Arial, Helvetica, sans-serif;
  }
  
  .card-run:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: -16px;
    right: -16px;
    background: linear-gradient(185deg, #06082f, #47b49a);
    height: 32px;
    width: 42px;
    border-radius: 32px;
    transform: scale(1);
    transform-origin: 50% 50%;
    transition: transform 0.35s ease-out;
  }
  
  .card-run:hover:before {
    transform: scale(28);
  }
  
  .card-run:hover .small-desc {
    transition: all 0.5s ease-out;
    color: rgba(255, 255, 255, 0.8);
  }
  
  .card-run:hover .card-title1 {
    transition: all 0.5s ease-out;
    color: #ffffff;
  }
  
/* why choose corporate ends  */

@media only screen and (max-width:1080px)
{
    .corpbg
{
    background-image: url('../Components/images/pic_paralax_2.jpg');
    width: 100%;
    height: 700px;
}
}