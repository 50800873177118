.loader{
    width: 100%;
    height: 100vh;
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    background: #47b49a;
    background: linear-gradient(90deg, rgba(71, 180, 154, 1) 0%, rgba(27, 94, 60, 1) 100%);
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader svg{
    width: 80%;
}


.loading-text{
    width: 100%;
    font-weight: bold;
    /* color: #fff; */
    transform: translate(.4rem);
    margin-top: 1rem;
    background: -webkit-linear-gradient(rgba(51, 172, 203, 1) 0%, rgb(255, 255, 255));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 24px;
}

@media only screen and (min-width: 360px) and (max-width: 640px)

{
    .loader{
        width: 100%;
        height: 100vh;
        z-index: 10;
        position: fixed;
        top: 0;
        left: 0;
        background: rgba(51, 172, 203, 1);
        background: linear-gradient(90deg, rgba(51, 172, 203, 1) 0%, rgba(6, 8, 38, 1) 100%);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .loader svg{
        width: 100%;
    }
    
    .loading-text{
        width: 100%;
        font-weight: bold;
        color: #fff;
        transform: translate(.4rem);
        margin-top: 1rem;
        margin-left: 25px;
    }
.main-logo
{
margin-left: 25px;
width: 100px;
}


}