.faq{
    font-weight: 500;
    font-size: 30px;
}
  .main-btn
  {
    color: #47b49a;
    background-color: white;
    border: none;
    font-family: poppins;
    font-size: 22px;
  }
  .main-btn:focus
  {
    outline: none ;
    box-shadow: none;
    background-color: white;
    border-bottom: 2px solid rgb(161, 161, 161);
  }

