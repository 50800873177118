@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
*
{
    margin: 0%;
    padding: 0%;
    font-family: poppins;
    box-sizing: border-box;
}



@media screen and (min-width: 240px) and (max-width: 360px)
{
  
   .more-know1
   {
     width: 120px;
   height: 30px;
   background-color: #47b49a;
   color: white;
   text-align: center;
   line-height: 30px;
   font-weight: 600;
   border-radius:20px;
   font-size: 10px;
   }
   
}
 @media screen and (min-width: 360px) and (max-width: 740px)
{
   
 
.more-know1
{
  width: 150px;
height: 30px;
background-color: #47b49a;
color: white;
text-align: center;
line-height: 30px;
font-weight: 600;
border-radius:10px;
font-size: 9px;

}

}
@media screen and (min-width: 740px) and (max-width: 758px)
{

 .more-know1
 {
   width: 100px;
 height: 30px;
 background-color: #47b49a;
 color: white;
 text-align: center;
 line-height: 30px;
 font-weight: 600;
 border-radius:20px;
 font-size: 12px;
 }
 
}
