.contact-btn1{
 background: #47b49a;
 border: 0;
 padding: 10px;
  width: 100px;
  font-size: 18px;
  color: white;

}
.contact-btn1:hover{

    color: white;
    background-color: #06082f;
    border: none;

}
.contact-heading{
  
    font-weight: 600;
}
.contact-sub-heading{
    font-weight: 400;
    color: rgb(141, 141, 141);
    font-size: 14px;
}
.form-label{
    font-weight: 400;
    font-size: 14px;
    color: rgb(135, 135, 135);


}

.email-class::placeholder{
    color: rgb(135, 135, 135);
    font-size: 14px;
}


.form-control:focus{
    outline: none;
    box-shadow: none;
  
}


.form-control:focus,


.form-control:active {
   outline: auto;      
}

input{
    height: 50px;
   
}
.map{
    margin-top: 5em;
}


.contact-banner-heading{
    margin-top: 1.1em;
    font-size: 70px;
    font-weight: 100;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    color: rgb(48, 48, 48);
    
    
}

.contact-subheading{
    font-size: 18px;
    color: #47b49a;
    margin-top: 20px;
}
.contact-para{
    font-size: 16px;
    margin-top: 20px;
    color: rgb(126, 126, 126);
}
